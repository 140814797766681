/*Elements*/
/*h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}
*/
h1 {
    /*font-family: SiemensSans_Black, Arial;*/
    font-family: SiemensSans_Black, Arial, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 48px;
    line-height: 54px;
}

h2 {
    /*font-family: SiemensSans_Bold, Arial;*/
    font-family: SiemensSans_Bold, Arial, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 32px;
    line-height: 38px;
}

h3 {
    /*font-family: SiemensSans_Bold, Arial;*/
    font-family: SiemensSans_Bold, Arial, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 26px;
    line-height: 32px;
}

h4 {
    /*font-family: SiemensSans_Bold, Arial;*/
    font-family: SiemensSans_Bold, Arial, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 20px;
    line-height: 26px;
}

h5 {
    /*font-family: SiemensSans_Bold, Arial;*/
    font-family: SiemensSans_Bold, Arial, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 16px;
    line-height: 22px;
}

h6 {
    /*font-family: SiemensSans_Bold, Arial;*/
    font-family: SiemensSans_Bold, Arial, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 12px;
    line-height: 18px;
}

li {
    color: #000028 !important;
}

a {
    cursor: pointer;
}

span {
    /*font-family: SiemensSans_Roman, Arial !important;*/
    font-family: SiemensSans_Roman, Arial, Helvetica, Verdana, Tahoma, sans-serif !important;
}

p {
    /*font-family: SiemensSans_Roman, Arial !important;*/
    font-family: SiemensSans_Roman, Arial, Helvetica, Verdana, Tahoma, sans-serif !important;
    font-size: 16px;
    line-height: 24px;
}

label {
    /*font-family: SiemensSans_Roman, Arial !important;*/
    font-family: SiemensSans_Roman, Arial, Helvetica, Verdana, Tahoma, sans-serif !important;
    /*SiemensSansGlobal-Regular font family is missing*/
    /*font-family: SiemensSansGlobal-Regular;*/
}

input {
    font-family: SiemensSans_Roman, Arial, Helvetica, Verdana, Tahoma, sans-serif !important;
    /*font-family: SiemensSans_Roman, Arial !important;*/
    /*SiemensSansGlobal-Regular font family is missing*/
    /*font-family: SiemensSansGlobal-Regular;*/
}

.text-error {
    /*font-family: SiemensSans_Roman, Arial !important;*/
    font-family: SiemensSans_Roman, Arial, Helvetica, Verdana, Tahoma, sans-serif !important;
}

/*Custom Dark Buttons*/
.ns-btn-hero {
    background: linear-gradient(to right, #00FFB9, #00E6DC);
    color: #000000;
    height: 64px;
    width: 238px;
    transition: background 0.5s !important;
    border: 0 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 20px !important;
}

    .ns-btn-hero:hover {
        background: linear-gradient(to right, #00FFB9, #00FFB9);
        transition: background 0.5s !important;
    }

    .ns-btn-hero:focus {
        background: linear-gradient(to right, #00E6DC, #00E6DC);
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-primary {
    background: #005673 !important;
    color: #FFFFFF !important;
    /*height: 54px;
    width: 206px;*/
    height: 40px;
    width: 180px;
    transition: background 0.5s !important;
    border: 0 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 20px;
}

.ns-btn-primary:hover {
    background-color: #0098A6 !important;
    transition: background 0.5s !important;
}

.ns-btn-primary:focus {
    background: #4DFFF9 !important;
    color: #000028 !important;
    transition: background 0.5s !important;
    outline: #00bedc solid 0px;
}

.ns-btn-secondary {
    background: #000028 !important;
    color: #2EE6E2 !important;
    height: 54px;
    width: 206px;
    transition: background 0.5s !important;
    border: 0px solid transparent !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 20px !important;
}

    .ns-btn-secondary:hover {
        background: #00193C !important;
        border: 2px solid #2EE6E2 !important;
        transition: background 0.5s !important;
    }

    .ns-btn-secondary:focus {
        background: #002949 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-tertiary {
    background: #000028 !important;
    color: #2EE6E2 !important;
    height: 54px;
    width: 206px;
    transition: background 0.5s !important;
    border: 0px solid transparent !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 20px !important;
}

    .ns-btn-tertiary:hover {
        background: #00193C !important;
        border: 0px solid #00193C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-tertiary:focus {
        background: #002949 !important;
        border: 0px solid #002949 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

/*Custom Light Button*/
.ns-btn-hero-light {
    background: linear-gradient(to right, #00FFB9, #00E6DC);
    color: #000000;
    height: 40px;
    width: 160px;
    transition: background 0.5s !important;
    border: 0 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 20px !important;
}

    .ns-btn-hero-light:hover {
        background: linear-gradient(to right, #00FFB9, #00FFB9);
        transition: background 0.5s !important;
    }

    .ns-btn-hero-light:focus {
        background: linear-gradient(to right, #00E6DC, #00E6DC);
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-primary-light {
    background: #0098A6 !important;
    color: #000000;
    height: 40px;
    min-width: 100px !important;
    transition: background 0.5s !important;
    border: 0 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 0px !important;
    float: right;
}

    .ns-btn-primary-light:hover {
        background-color: #00708C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-primary-light:focus {
        background: #005673 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-secondary-light {
    background: #008AA6 !important;
    color: #FFFFFF !important;
    height: 40px;
    min-width: 100px !important;
    transition: background 0.5s !important;
    border: 2px solid #008AA6 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    float: right;
}

    .ns-btn-secondary-light:hover {
        background: #008AA6 !important;
        border: 2px solid #00708C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-secondary-light:focus {
        background: #008AA6 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-tertiary-light {
    background: #FFFFFF !important;
    color: #00708C !important;
    height: 40px;
    min-width: 100px !important;
    transition: background 0.5s !important;
    border: 0px solid transparent !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    margin: 0 0px !important;
    /*Float right is used for a very specific case*/
    float: right;
}

    .ns-btn-tertiary-light:hover {
        background: #E0F9F9 !important;
        border: 0px solid #00193C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-tertiary-light:focus {
        background: #CCF5F5 !important;
        border: 0px solid #002949 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-upload {
    background: #008AA6 !important;
    color: #FFFFFF !important;
    height: 30px !important;
    min-width: 100px !important;
    width: max-content !important;
    margin: 12px -100px 0 0 !important;
    transition: background 0.5s !important;
    border: 2px solid #008AA6 !important;
    border-radius: 100px !important;
    /* overflow-wrap: anywhere !important; */
    word-break: break-all !important;
    line-height: 20px !important;
    float: right;
}

    .ns-btn-upload:hover {
        background: #008AA6 !important;
        border: 2px solid #00708C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-upload:focus {
        background: #008AA6 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }
/*Cognisphere Home Screen Video*/
.home-user-description {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: fixed !important;
    left: 0;
    padding: 88px !important;
}

.description-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: calc(25vh);
}

.get-MyId-username {
    padding-top: 15px;
    padding-left: 50px;
}

.project-desc {
    padding: 0 50px !important;
    margin-bottom: 100px !important;
    font-size: 24px !important;
    letter-spacing: 0.09em !important;
    font-weight: 600 !important;
}

.homepage-master-btn {
    position: relative !important;
    left: -45px;
}

.cognisphere-vid-block {
    height: 100%;
    width: 100%;
    position: fixed !important;
    right: 0;
}

    .cognisphere-vid-block > video {
        object-fit: cover;
        position: fixed;
        top: 0;
        right: 0;
    }

.description-bdr {
    position: absolute;
    left: 0;
    top: .3rem;
    bottom: .6rem;
    background: linear-gradient(180deg, #00ffb9, #00e6dc);
    padding-left: 8px;
    max-height: 4.5rem;
}

.MuiTooltip-popper > div {
    background: #4DFFF9 !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: bold;
}

.test-p {
    padding: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    background: #004057;
    margin: 30px 0;
}

.accordian-mg-btm-20 {
    margin-bottom: 20px;
}

.text-heading-name {
    margin: 20px 50px !important;
}

.ns-btn-reject {
    background: #ef0137 !important;
    color: #ffffff !important;
    /*height: 54px;
    width: 206px;*/
    height: 15px;
    width: 70px;
    padding: 10px 5px !important;
    transition: background 0.5s !important;
    border: 0 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    /*word-break: break-all !important;*/
    /*    line-height: 18px !important;*/
}

.ns-btn-approve {
    background: #00CCCC !important;
    color: #ffffff !important;
    /*height: 54px;
    width: 206px;*/
    height: 15px;
    width: 70px;
    padding: 10px 5px !important;
    transition: background 0.5s !important;
    border: 0 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    /*word-break: break-all !important;*/
    /*    line-height: 18px !important;*/
}

.ns-btn-hyperlink {
    background: #ffffff !important;
    color: #00CCCC !important;
    /*height: 54px;
    width: 206px;*/
    height: 15px;
    width: 80px;
    padding: 5px 10px !important;
    transition: background 0.5s !important;
    border-radius: 100px;
}

    .ns-btn-hyperlink:hover {
        background-color: #2EE6E2 !important;
        color: #000000 !important;
        transition: background 0.5s !important;
        cursor: pointer !important;
    }

.MuiAccordionSummary-root {
    background: #00B3B3 !important;
    color: #FFFFFF !important;
}

.MuiCircularProgress-colorPrimary {
    color: #2EE6E2 !important;
}

.scrollTop {
    /*position: fixed;
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;*/
    opacity: 0.3;
    background-color: #2EE6E2 !important;
    min-width: 40px !important;
    height: 30px !important;
    position: fixed !important;
    bottom: 10px;
    right: 5px;
    border-radius: 5px;
    border: none;
    animation: fadeIn 0.3s;
    z-index: 1000;
}

    .scrollTop:hover {
        opacity: 1;
    }

.arrow-up {
    color: white !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    margin-top: -17px !important;
    margin-left: -17px !important;
}

.chooseFileButton {
    padding: 0 0 0 0 !important;
    background: #3f4257;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    margin-right: 0 !important;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none
}

.ns-btn-delete-doc {
    background: #008AA6 !important;
    color: #FFFFFF !important;
    height: max-content !important;
    min-width: max-content !important;
    transition: background 0.5s !important;
    border: 2px solid #008AA6 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    float: right;
}

    .ns-btn-delete-doc:hover {
        background: #008AA6 !important;
        border: 2px solid #00708C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-delete-doc:focus {
        background: #008AA6 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }

.ns-btn-custom {
    background: #008AA6 !important;
    color: #FFFFFF !important;
    height: max-content !important;
    min-width: max-content !important;
    transition: background 0.5s !important;
    border: 2px solid #008AA6 !important;
    border-radius: 100px !important;
    /*overflow-wrap: anywhere !important;*/
    word-break: break-all !important;
    line-height: 20px !important;
    float: right;
}

    .ns-btn-custom:hover {
        background: #008AA6 !important;
        border: 2px solid #00708C !important;
        transition: background 0.5s !important;
    }

    .ns-btn-custom:focus {
        background: #008AA6 !important;
        transition: background 0.5s !important;
        outline: #00bedc solid 0px;
    }
