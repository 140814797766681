/*Siemens Sans*/
@font-face {
    font-family: "SiemensSans_Black";
    src: url("../fonts/SiemensSans_Black.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSans_BlackItalic";
    src: url("../fonts/SiemensSans_BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSans_Bold";
    src: url("../fonts/SiemensSans_Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSans_BoldItalic";
    src: url("../fonts/SiemensSans_BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSans_Italic";
    src: url("../fonts/SiemensSans_Italic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSans_Roman";
    src: url("../fonts/SiemensSans_Roman.ttf") format("truetype");
}

/*Siemens Serif*/
@font-face {
    font-family: "SiemensSerif_Bold";
    src: url("../fonts/SiemensSans_Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSerif_BoldItalic";
    src: url("../fonts/SiemensSans_BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSerif_Italic";
    src: url("../fonts/SiemensSans_Italic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSerif_Roman";
    src: url("../fonts/SiemensSans_Roman.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSerif_Semibold";
    src: url("../fonts/SiemensSerif_Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSerif_SemiboldItalic";
    src: url("../fonts/SiemensSerif_SemiboldItalic.ttf") format("truetype");
}

/*Siemens Slab*/
@font-face {
    font-family: "SiemensSlab_Black";
    src: url("../fonts/SiemensSlab_Black.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSlab_BlackItalic";
    src: url("../fonts/SiemensSlab_BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSlab_Bold";
    src: url("../fonts/SiemensSlab_Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSlab_BoldItalic";
    src: url("../fonts/SiemensSlab_BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSlab_Italic";
    src: url("../fonts/SiemensSlab_Italic.ttf") format("truetype");
}

@font-face {
    font-family: "SiemensSlab_Roman";
    src: url("../fonts/SiemensSlab_Roman.ttf") format("truetype");
}