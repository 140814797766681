html, body {
    margin: 0;
    padding: 0;
    color: #000028;
    background: #FFFFFF;
    box-sizing: border-box;
    /*background: linear-gradient(to bottom, #000028, #006450);*/
    /*	background: linear-gradient(to bottom, #000028, #009999);
	background-repeat: no-repeat;
	background-attachment: fixed;*/
}

.nomination_main {
    position: relative;
    top: 50px;
    width: 100%;
}

.master-layout-block {
    position: relative;
    margin: 30px 86px 0;
}

.master-layout-blockPrint {
    position: relative;
    margin: 30px 30px 0;
}

.master-layout-data {
    position: relative;
    margin: 30px 10px 0;
}
/*Scroll*/
body::-webkit-scrollbar {
    width: 0.5rem;
}

body::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
    box-shadow: none;
}

body::-webkit-scrollbar-thumb {
    /*background: linear-gradient(to bottom, #00D7A0, #00BEDC);*/
    background: #00CCCC;
    /*outline: 1px solid #000028 !important;*/
}

    body::-webkit-scrollbar-thumb:hover {
        /*background: linear-gradient(to bottom, #00D7A0, #00BEDC);*/
        background: #00CCCC;
        /*outline: 1px solid #000028 !important;*/
    }

/*  Padding classes */
.p-0 {
    padding: 0 !important;
}

.px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 1em !important;
}

.pl-2 {
    padding-left: 2em !important;
}

.pl-3 {
    padding-left: 3em !important;
}

.pl-4 {
    padding-left: 4em !important;
}

.pl-5 {
    padding-left: 5rem !important;
}

.pr-0 {
    padding-left: 0 !important;
}

.pr-2 {
    padding-right: 2rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

/*  Margin classes */
.m-0 {
    margin: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-negem-1 {
    margin-left: -1em !important;
}

.ml-negem-2 {
    margin-left: -2em !important;
}

.mr-15px {
    margin-right: 15px !important;
}

.my-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.mt-minus1 {
    margin-top: -1rem !important;
}

.mr-0 {
    margin-left: 0 !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.mr-3 {
    margin-right: 3rem !important;
}

.mr-4 {
    margin-right: 4rem !important;
}

.mr-6 {
    margin-right: 6rem !important;
}

.mr-12-ve {
    margin-right: -12rem !important;
}

.mr-14-ve {
    margin-right: -14rem !important;
}

.mr-1e {
    margin-right: 1em !important;
}

.mr-2e {
    margin-right: 2em !important;
}

.mr-3e {
    margin-right: 3em !important;
}

.mr-5e {
    margin-right: 5em !important;
}

.mr-8e {
    margin-right: 8em !important;
}

.mr-10e {
    margin-right: 10em !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.mt-1-ve {
    margin-top: -1rem !important;
}

.mt-2-ve {
    margin-top: -2rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-1e {
    margin-top: 1em !important;
}

.mt-2e {
    margin-top: 2em !important;
}

.mt-3e {
    margin-top: 3em !important;
}

.mt-4e {
    margin-top: 4em !important;
}

.mt-5e {
    margin-top: 5em !important;
}

.mt-8e {
    margin-top: 8em !important;
}

.mT-1e {
    margin-top: -1em !important;
}

.mt-05 {
    margin-top: 0.5rem !important;
}

.m-0 {
    margin: 0px !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-4 {
    margin-top: 4rem !important;
}

.mt-5 {
    margin-top: 5rem !important;
}

.mt-7 {
    margin-top: 7rem !important;
}

.mt-8 {
    margin-top: 8rem !important;
}

.mt-6 {
    margin-top: 6rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mb-4 {
    margin-bottom: 4rem !important;
}

.mb-5 {
    margin-bottom: 5rem !important;
}

.mb-6 {
    margin-bottom: 6rem !important;
}

.mb-8 {
    margin-bottom: 8rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.p-1 {
    padding: 1rem;
}

.p-1e {
    padding: 1em;
}

.pl-1 {
    padding-left: 1rem !important;
}

.pt-6 {
    padding-top: 6.5rem !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-13 {
    padding-top: 13px !important;
}
/*  Color classes */
.clr-purple {
    color: #9D52BA;
}

.clr-dark-purple {
    color: #331131;
}

.clr-light-green {
    color: #00B3B3 !important;
}

.clr-dark-green {
    color: #004B56 !important;
}

.clr-blue {
    color: #2095E2;
}

.clr-dark-blue {
    color: #000028 !important;
}

.clr-cyan {
    color: #49DA88;
}

.clr-amber {
    color: #FDB92B;
}

.clr-red {
    color: #EF0137 !important;
}

.clr-orange {
    color: #EB780A;
}

.clr-dark-orange {
    color: #FF5454 !important;
}

.clr-light-cyan {
    color: #B7E0E0 !important;
}

.clr-white {
    color: #FFFFFF !important;
}

/*  Background classes */
.bg-purple {
    background-color: #9D52BA;
}

.bg-popup {
    background-color: #CCF5F5 !important;
}

.bg-content {
    background-color: #CCF5F5 !important;
}

.bg-soft-blue {
    background-color: #00BEDC !important;
}

.bg-blue {
    /*background-color: #003c8f !important;*/
    background-color: #004057 !important;
}

.bg-light-sand {
    background: #F3F3F0;
}

.bg-dark-blue {
    background-color: #000028 !important;
}

.bg-bold-blue {
    background-color: #00E6DC !important;
}

.bg-light-blue {
    background-color: #EBF0F5;
}

.bg-grad--soft-dynamic-petrol {
    background: linear-gradient(to right, #00D7A0, #00BEDC);
}

.bg-grad--bold-dynamic-petrol {
    background: linear-gradient(to right, #00FFB9, #00E6DC);
}


.bg-cyan {
    background-color: #49DA88;
}

.bg-light-cyan {
    background: #B7E0E0 !important;
}

.bg-amber {
    background-color: #FDB92B !important;
}

.bg-orange {
    background-color: #EB780A !important;
}


.bg-warning {
    background-color: #EA4B35;
}

.bg-error {
    background-color: #EF0137;
}

.bg-success {
    background-color: #00FFB9;
}

.bg-alert {
    background-color: #00B3B3;
}

.bg-grey {
    background-color: #F7F7F7;
}

.bg-grey-ccc {
    background-color: #CCC;
}

.bg-light-grey {
    background-color: #D0D3DA;
}

.bg-grey-fa {
    background-color: #FAF7F7;
}

.bg-dark-grey {
    background-color: #949EAA !important;
}

.bg-white {
    background-color: #FFFFFF !important;
}

.bg-transparent {
    background-color: transparent;
}

/*  Border classes */
/* NOTE : .bdr classes will be 1px solid. If any other border is required, it will be available separately and NOT AS ".bdr-color" class name. */
.bdr-purple {
    border: 1px solid #9D52BA;
}

.bdr-blue {
    border: 1px solid #009999 !important;
}

.bdr-light-blue {
    border: 1px solid #CDD9E1;
}

.bdr-dark-blue {
    border: 1px solid #0A6287;
}

.bdr-cyan {
    border: 1px solid #49DA88;
}

.bdr-amber {
    border: 1px solid #FDB92B;
}

.bdr-red {
    border: 1px solid #EA4B35 !important;
}

.bdr-light-grey {
    border: 1px solid #D0D3DA;
}

.bdr-radius-null {
    border-radius: 0 !important;
}

.bdr-transparent {
    border: 1px solid transparent;
}

.bdr-none {
    border: none;
}

/*Position*/
.position-absolute {
    position: absolute !important;
}

.position-relative {
    position: relative !important;
}

.position-fixed {
    position: fixed !important;
}

/*Filter invert*/
.filter-invert-0 {
    filter: invert(0);
}

.filter-invert-1 {
    filter: invert(1);
}

/*Float*/
.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

/* Height - Inherit */
.height-inherit {
    height: inherit !important;
}

/*Text classes*/
.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-center {
    text-align: center;
}

/*Height and width*/
.width-10 {
    width: 10% !important;
}

.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.width-25 {
    width: 25%;
}

.width-30 {
    width: 30%;
}

.width-35 {
    width: 35%;
}


.width-40 {
    width: 40%;
}

.width-45 {
    width: 45%;
}

.width-50 {
    width: 50%;
}


.width-55 {
    width: 55%;
}


.width-60 {
    width: 60%;
}

.width-65 {
    width: 65%;
}

.width-70 {
    width: 70%;
}

.width-75 {
    width: 75%;
}

.width-80 {
    width: 80%;
}

.width-85 {
    width: 85%;
}

.width-90 {
    width: 90%;
}

.width-100 {
    width: 100% !important;
}

.height-100 {
    height: 100% !important;
}
/* Font */

.f-8 {
    font-size: 8px !important;
}

.f-10 {
    font-size: 10px !important;
}

.f-12 {
    font-size: 12px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-20 {
    font-size: 20px !important;
}

.f-22 {
    font-size: 22px !important;
}

.f-24 {
    font-size: 24px !important;
}

.f-26 {
    font-size: 26px !important;
}

.f-28 {
    font-size: 28px !important;
}

.f-30 {
    font-size: 30px !important;
}

/* font weight */
.fw-bold {
    font-weight: bold !important;
}

.fw-500 {
    font-weight: 500 !important;
}

/*Oveflow*/

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

/*z-index*/
.z-0 {
    z-index: 0;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.z-4 {
    z-index: 4;
}

.z-5 {
    z-index: 5;
}

.z-10 {
    z-index: 10;
}

.z-100 {
    z-index: 100;
}

.z-1000 {
    z-index: 1000;
}

.z-9999 {
    z-index: 9999;
}


/*min-width*/
.mw-100 {
    min-width: 100% !important;
}

/*display*/
.d-flex {
    display: flex !important;
}

.d-block {
    display: block;
}

.d-none {
    display: none !important;
}

/*Flex-direction*/
.fd-column {
    flex-direction: column;
}

.fd-column-rev {
    flex-direction: column-reverse;
}

.fd-row {
    flex-direction: row;
}

.fd-row-rev {
    flex-direction: row-reverse;
}


/*Flex-wrap*/
.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-rev {
    flex-wrap: wrap-reverse
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

/*align*/
.align-self-center {
    align-self: center;
}

.align-item-center {
    align-items: center;
}

.align-center {
    align-items: center;
}

/*justify content - jc*/
.jc-space-bt {
    justify-content: space-between !important;
}

.jc-space-evenly {
    justify-content: space-evenly !important;
}

.jc-space-around {
    justify-content: space-around !important;
}

.jc-center {
    justify-content: center !important;
}

.jc-flex-start {
    justify-content: flex-start;
}

.jc-flex-end {
    justify-content: flex-end;
}

.jc-start {
    justify-content: start !important;
}

.jc-end {
    justify-content: end !important;
}

/*Top, right, bottom, left*/
.left-15 {
    left: 15px;
}

/* Flex properties */
.flex-grow-2 {
    flex-grow: 2;
}

/*Line Height*/
.lh-1 {
    line-height: 1 !important;
}

.lh-2 {
    line-height: 2 !important;
}

/* Items in center - vertical and horizontal */
.item-in-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

/* UL LI list styles */
.li-style-none {
    list-style: none;
}

/* White-space */
.ws-no-wrap {
    white-space: nowrap;
}

/* Buttons */
.btn-dark-blue {
    background: #005578;
    color: #FFFFFF !important;
}

    .btn-dark-blue:hover {
        color: #FFFFFF !important;
    }
/*Font-weight*/


/*Header*/
.header-dark-bg {
    background-color: #3c464b;
}

.header-light-bg {
    background: #000028;
}

.Width-100 {
    width: 100% !important;
}

.Width-50 {
    width: 50% !important;
}

.width-25 {
    width: 25% !important;
}

.lightInputFields {
    width: 100% !important;
}

.noBgLightInputField {
    background: none !important;
}

.hover-blue {
    color: #008AA6 !important;
}

    .hover-blue:hover {
        border-bottom: 1px solid #008AA6;
    }

.bdr-bottom {
    border-bottom: 1px solid #004B56;
}

.cst-textarea {
    border: 0px !important;
    background: #E8F8F8 !important;
    /*font-family: SiemensSans_Roman, Arial !important;*/
    font-family: SiemensSans_Roman, Arial, Helvetica, Verdana, Tahoma, sans-serif !important;
    font-size: 16px;
    height: 51px !important;
}

.box-shadow {
    box-shadow: 0px 3px 10px 5px #CCCCCC80;
}

.bdr-radius {
    border-radius: 25px;
}
/*.appbar-icon {
    background: #4DFFF9;
}*/
#otp-helper-text {
    color: red !important;
    background-color: white;
    margin: 0px 0px 0px 0px !important;
}

.cstLineHeight {
    line-height: 3.5rem !important;
    color: #2EE6E2 !important;
}

.clr-text-header {
    color: #2EE6E2 !important;
}

.text-danger {
    color: #FF9900 !important;
}

.width-100 {
    width: 100%;
}

.width-50 {
    width: 50%;
}

.width-25 {
    width: 25%;
}

.custom-font {
    /*font-family: Arial Narrow;*/
    font-family: SiemensSans_Roman, Arial Narrow, Helvetica, Verdana, Tahoma, sans-serif;
    font-size: 12px;
}

.table-border {
    border: #999 1px solid;
}

.fw-bold {
    font-weight: bold;
}

.border-collapse {
    border-collapse: collapse;
}

.borderWidth {
    border-width: 0px;
}

.h-25 {
    height: 25px;
}

.h-100 {
    height: 85px;
}

.h-130 {
    height: 130px;
}

.h-50 {
    height: 50px;
}

.h-30 {
    height: 30px;
}

.Mui-disabled {
    color: #000028;
    /*border: 0px solid #008AA6 !important;*/
    background: #ccc !important;
}

    .Mui-disabled::before {
        border-bottom: 0px !important;
        border-bottom-style: none !important;
    }

/*SHPL Preview*/
.border-grey {
    border: 1px solid #CCC;
}

.pl-2 {
    padding-left: 2rem;
}

.bdr-black {
    border: 1px solid #000;
    padding-left: 1em !important;
}

.bdr-bottom-black {
    border-bottom: 1px solid #000;
}

.vertical-middle {
    vertical-align: middle;
}

.h-0 {
    height: none !important;
}

.h-max {
    height: max-content !important;
}

.h-40 {
    height: 55px;
}

.h-20 {
    height: 20px;
}

.h-25 {
    height: 25px;
}

.h-30 {
    height: 30px;
}

.h-80 {
    height: 80px;
}

.h-70 {
    height: 70px;
}

.bdr-header {
    border-bottom: 2px solid #000 !important;
    font-weight: bold !important;
    font-size: 12px !important;
}

@media print {
    .pagebreak {
        page-break-before: always;
    }

    .pagebreakAfter {
        page-break-after: always;
    }

    .no-print {
        display: none !important;
        visibility: hidden !important;
    }

    .disableOnPrint-scrollToTop {
        display: none !important;
        visibility: hidden !important;
    }
}

.l-40 {
    line-height: 40px !important;
}

.l-10 {
    line-height: 10px !important;
}

.l-30 {
    line-height: 30px !important;
}

.pt-6px {
    padding-top: 6px !important;
}

.font-italic {
    font-style: italic;
}

.mR-5 {
    margin-right: -3.5rem;
}

.mt-1bt2 {
    margin-top: 1.5rem !important;
}

.mt-0bt2 {
    margin-top: 0.5em !important;
}

.mr-6i {
    margin-right: 6rem !important;
}

.ml-77 {
    margin-left: 7.7rem;
}

.ml-08 {
    margin-left: 0.8rem;
}

.ml-78 {
    margin-left: 7.8rem;
}

.mr-11 {
    margin-right: 11rem !important;
}

.mr-8 {
    margin-right: 8em !important;
}

.mr-05 {
    margin-right: 0.5rem;
}

.p-10px {
    padding: 10px;
}

.pl-10px {
    padding-left: 20px !important;
}

.box-shadow-none {
    box-shadow: none !important;
}

.fileContainer {
    box-shadow: none !important;
    margin: 0px;
    padding: 0px;
    font-size: 12px;
}

@media (max-width:600px) {
    .my-nav {
        margin-top: 1rem !important;
    }
}

@media (max-width:600px) {
    .sm-600 {
        margin-right: 4rem !important;
    }
}

@media (max-width:600px) {
    .mPL-3 {
        padding-left: 3rem !important;
    }
}

@media (max-width:600px) {
    .media-jc-center {
        justify-content: center !important;
    }
}

.mt-10px {
    margin-top: 6px !important;
    margin-left: 1rem !important;
}

:focus {
    outline: none !important;
}

.css-1pahdxg-control:hover {
    border: none !important;
    border-color: transparent !important;
}

.css-yk16xz-control {
    border-color: #ccc !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.css-1pahdxg-control {
    box-shadow: none !important;
}

.border-color-textarea-validate {
    width: 100% !important;
    border-color: red !important;
}

.para-pjq {
    font-size: 11px !important;
    line-height: 16px !important;
}

.profileimg {
    height: 120px;
    width: 100px;
    border: solid 1px #E8F8F8;
}

.InputFile {
    height: 37px !important;
}

.InputLable {
    color: green;
}

#percentagemarks-helper-text {
    background: none !important;
}

#englishper-helper-text {
    background: none !important;
}

#mathmaticspermarks-helper-text {
    background: none !important;
}

#sciencepermarks-helper-text {
    background: none !important;
}

.helper-text-disabled {
    background: none !important;
}

.Box-padding {
    padding-top: 40px;
}

@media screen and (min-width:360px) and (max-width:992px) {
    #CognisphereVid {
        display: none;
    }

    .headingBlock {
        margin-left: 15px;
    }

    .master-layout-block {
        padding-top: 80px;
    }

    .Box-padding {
        padding-top: 0px;
    }
}

.pd-mobile-10 {
    padding: 10px 20px;
}

.otp-padding {
    padding-top: 60px;
}
