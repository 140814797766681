.siemens-logo {
    height: 20px !important;
}

.siemens-logo-dark {
    height: 25px;
    margin: 20px;
}

.burger-icon {
    position: relative;
    height: 20px;
    top: -2px;
}

.appbar-icon {
    /*background: linear-gradient(to right, #00FFB9, #00E6DC) !important;
    color: #000000 !important;
    border-radius: 15px;*/
    padding: 3px;
}
