.ImageBlock {
    max-width: 100%;
}
.heading1 {
    font-size: 35px;
    color: white;
    line-height: 35px;
}
.heading2 {
    font-size: 22px;
    color: white;
}
.logo {
    font-size: 35px;
    color: white;
    text-align:right;
}
.hiringstaticgrid {
    background-color: #000028;
}
.headingBlock {
    border-left: 5px solid #00ffb9;
    margin-left: 55px;
    padding-left: 15px;
}
.paddingBottom{
    padding-bottom:10px;
}
.nextpagebtn {
    padding-bottom: 40px;
    text-align: right;
    padding-right: 40px;

}
.loginpageDate {
    font-size: 13px;
}
.otpheadingtext{
    text-align:center;
}
.privacyBtn {
    text-align: center;
}
.MuiFormHelperText-filled{
    background-color:#ffffff !important;
}