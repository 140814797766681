@media only screen and (max-width: 600px) {
    .master-layout-block {
        margin: 10px 22px;
    }

    .home-user-description {
        padding: 40px !important;
    }

    .get-MyId-username {
        padding-top: 15px;
        padding-left: 25px;
        font-size: 36px;
    }

    .project-desc {
        padding: 0 100px 0 25px !important;
        margin-bottom: 50px;
    }

    /*.cognisphere-vid-block > video {
        right: -65px;
    }*/

    .description-block {
        top: calc(20vh);
    }

    .ns-btn-hero {
        height: 50px;
        width: 200px;
    }

    .homepage-master-btn {
        left: -45px !important;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .home-user-description {
        padding: 50px !important;
    }

    .get-MyId-username {
        padding-left: 40px !important;
        font-size: 40px;
    }

    .project-desc {
        padding: 0 25px 0 40px !important;
        margin-bottom: 100px !important;
    }

    /*.cognisphere-vid-block > video {
        right: -65px;
    }*/

    .description-block {
        top: calc(20vh);
    }

    .ns-btn-hero {
        height: 50px;
    }

    .homepage-master-btn {
        left: 0 !important;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1280px) {
    .home-user-description {
        padding: 88px !important;
    }

    .get-MyId-username {
        font-size: 42px;
        padding-left: 40px !important;
    }

    .project-desc {
        padding: 0 40px !important;
        margin-bottom: 100px !important;
    }

    .cognisphere-vid-block > video {
        object-fit: cover;
        position: fixed;
        top: 0;
        right: 0;
    }

    .description-block {
        top: calc(20vh);
    }

    .ns-btn-hero {
        height: 50px;
    }

    .homepage-master-btn {
        left: -55px !important;
    }
}
