.drawer-user-name {
    padding: 5px 20px;
}

.drawer-chevron-icon {
    margin: 5px 0 !important;
}

.drawer-list-height {
    height: inherit;
}

.icon-left-pd {
    padding-left: 10px !important;
}

.list-item-text > span {
    font-weight: bold !important;
}

.drawer-footer-text {
    position: absolute;
    bottom: 0;
    padding: 20px;
    /*font-family: SiemensSans_Black;*/
    font-family: SiemensSans_Black, Helvetica, Verdana, Tahoma, sans-serif, Arial;
}